import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

const NavBar = () =>
  import(/* webpackChunkName: "sidebar" */ '@/components/Layout/NavBar');
const Meeting = () =>
  import(/* webpackChunkName: "meeting" */ '@/views/Meeting');
const SignInWeb = () =>
  import(/* webpackChunkName: "signinweb" */ '@/views/SignInWeb');
const SignIn = () => import(/* webpackChunkName: "signin" */ '@/views/SignIn');
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home');
const SignOut = () =>
  import(/* webpackChunkName: "signout" */ '@/views/SignOut');
const Meetings = () =>
  import(/* webpackChunkName: "meetings" */ '@/views/Meetings');
const Now = () => import(/* webpackChunkName: "now" */ '@/views/Now');
const Welcome = () =>
  import(/* webpackChunkName: "welcome" */ '@/views/Welcome');
const Actions = () =>
  import(/* webpackChunkName: "actions" */ '@/views/Actions');
const Profile = () =>
  import(/* webpackChunkName: "profile" */ '@/views/Profile');
const OnboardingTour = () =>
  import(/* webpackChunkName: "onboarding" */ '@/views/OnboardingTour');
const NotionConnect = () =>
  import(
    /* webpackChunkName: "notion" */ '@/views/Integrations/Notion/NotionConnect'
  );
const DriveConnect = () =>
  import(
    /* webpackChunkName: "drive" */ '@/views/Integrations/Drive/DriveConnect'
  );
const NotionSelectPage = () =>
  import(
    /* webpackChunkName: "notion" */ '@/views/Integrations/Notion/NotionSelectPage'
  );
const DriveSettings = () =>
  import(
    /* webpackChunkName: "drive" */ '@/views/Integrations/Drive/DriveSettings'
  );
const SlackConnect = () =>
  import(/* webpackChunkName: "notion" */ '@/views/Integrations/Slack/Connect');
const SlackSelectDefault = () =>
  import(
    /* webpackChunkName: "notion" */ '@/views/Integrations/Slack/SelectDefaultChannel'
  );
const Referral = () =>
  import(/* webpackChunkName: "referral" */ '@/views/Referral');
const TeamSubscriptionPage = () => import('@/views/Subscriptions/Team');
const CompanyPage = () => import('@/views/Subscriptions/Company');
const EmailPreview = () =>
  import(/* webpackChunkName: "emailpreview" */ '@/views/EmailPreview');
const NotFound = () =>
  import(/* webpackChunkName: "notfound" */ '@/views/NotFound');
const Forbidden = () =>
  import(/* webpackChunkName: "forbidden" */ '@/views/Forbidden');
const ErrorPage = () =>
  import(/* webpackChunkName: "forbidden" */ '@/views/Error');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      navigation: NavBar,
      content: Home,
    },
    props: true,
    meta: {
      title: 'Home | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/meetings',
    name: 'meetings',
    components: {
      navigation: NavBar,
      content: Meetings,
    },
    props: true,
    meta: {
      title: 'Meetings | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/signinweb',
    name: 'signinweb',
    components: {
      // navigation: none,
      content: SignInWeb,
    },
    meta: {
      title: 'Sign in | Meetric',
      requiresAuth: false,
    },
  },
  {
    path: '/signin',
    name: 'signin',
    components: {
      // navigation: none,
      content: SignIn,
    },
    meta: {
      title: 'Sign in | Meetric',
      requiresAuth: false,
    },
  },
  {
    path: '/signout',
    name: 'signout',
    components: {
      navigation: NavBar,
      content: SignOut,
    },
    meta: {
      title: 'Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/now',
    name: 'now',
    components: {
      navigation: NavBar,
      content: Now,
    },
    props: {
      content: true,
    },
    meta: {
      title: 'Meeting | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    components: {
      navigation: NavBar,
      content: Welcome,
    },
    meta: {
      title: 'Meeting | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/meetings/:id',
    name: 'meeting',
    components: {
      navigation: NavBar,
      content: Meeting,
    },
    props: {
      content: true,
    },
    meta: {
      title: 'Meeting | Meetric',
      requiresAuth: false,
    },
  },

  {
    path: '/actions',
    name: 'actions',
    components: {
      navigation: NavBar,
      content: Actions,
    },
    meta: {
      title: 'Actions | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      navigation: NavBar,
      content: Profile,
    },
    meta: {
      title: 'Profile | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    components: {
      // navigation: none,
      content: OnboardingTour,
    },
    meta: {
      title: 'Welcome | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/emailPreview',
    name: 'emailPreview',
    components: {
      navigation: NavBar,
      content: EmailPreview,
    },
    meta: {
      title: 'Email Preview | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/slackconnect',
    name: 'slackconnect',
    components: {
      navigation: NavBar,
      content: SlackConnect,
    },
    meta: {
      title: 'Connecting to Slack | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/slackselectdefault',
    name: 'slackselectdefault',
    components: {
      navigation: NavBar,
      content: SlackSelectDefault,
    },
    props: {
      content: true,
    },
    meta: {
      title: 'Select default channel | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/notionconnect',
    name: 'notionconnect',
    components: {
      navigation: NavBar,
      content: NotionConnect,
    },
    meta: {
      title: 'Connecting Notion | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/driveconnect',
    name: 'driveconnect',
    components: {
      navigation: NavBar,
      content: DriveConnect,
    },
    meta: {
      title: 'Connecting Drive | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/drivesettings',
    name: 'drivesettings',
    components: {
      navigation: NavBar,
      content: DriveSettings,
    },
    meta: {
      title: 'Drive settings | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/notionselect',
    name: 'notionselect',
    components: {
      navigation: NavBar,
      content: NotionSelectPage,
    },
    meta: {
      title: 'Notion integration | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/refer',
    name: 'referral',
    components: {
      navigation: NavBar,
      content: Referral,
    },
    meta: {
      title: 'Share the love | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/team-subscription',
    name: 'team-subscription',
    components: {
      navigation: NavBar,
      content: TeamSubscriptionPage,
    },
    meta: {
      title: 'Team subscription | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/company',
    name: 'company',
    components: {
      navigation: NavBar,
      content: CompanyPage,
    },
    meta: {
      title: 'Company | Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    components: {
      navigation: NavBar,
      content: Forbidden,
    },
    meta: {
      title: 'Meetric',
      requiresAuth: false,
    },
  },
  {
    path: '/error',
    name: 'error',
    components: {
      navigation: NavBar,
      content: ErrorPage,
    },
    props: {
      content: true,
    },
    meta: {
      title: 'Meetric',
      requiresAuth: true,
    },
  },
  {
    path: '/*',
    name: 'notfound',
    components: {
      navigation: NavBar,
      content: NotFound,
    },
    props: {
      content: true,
    },
    meta: {
      title: 'Meetric',
      requiresAuth: false,
    },
  },
  {
    path: '/chrome',
    name: 'chromeextension',
    meta: {
      title: 'Meetric',
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      window.open(
        'https://chrome.google.com/webstore/detail/meetric/imjdlmoaemnjongiaddmdgmpnomppdml',
        '_blank'
      );
      next(false);
    },
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: 'Meetric',
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      if (
        !(
          window.analytics &&
          window.analytics.Integrations &&
          window.analytics.Integrations.Intercom
        )
      ) {
        // when Segment blocked, open help page instead
        window.open('http://help.meetric.app', '_blank');
      }

      next(false);
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE,
});

function signedIn() {
  return Vue.gAuth.isAuthorized;
}

router.beforeEach((to, from, next) => {
  // console.log('rtr', from, to);

  if (to.query.meetric == 'extension') {
    store.commit('saveIsExtension', true);
    trackTakeNotes({ id: to.params.id, calendarId: to.query.cid });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (signedIn()) {
      if (to.name != 'now') document.title = to.meta.title || 'Meetric';
      next();
    } else {
      document.title = 'Sign in to Meetric';
      console.log('Redirecting');
      const q = { ...to.query };
      if (to.name != 'signout') q.r = to.path;
      next({
        name: 'signin',
        query: q,
      });
      return;
    }
  } else {
    document.title = to.meta.title || 'Meetric';

    next();
  }
});

router.afterEach((to, from) => {
  trackPage(to, from);
});

function trackPage(to, from) {
  // do not track home as it just redirects to actual page
  if (to.name == 'home') return;

  window.meetric.page(to.name, {
    from: from.path,
  });
}

// Add listener for Chrome extension
window.addEventListener('message', (event) => {
  if (typeof event.data == 'object' && event.data.action == 'meetric_route') {
    router.replace({
      name: event.data.name,
      params: event.data.params,
      query: event.data.query,
    });

    trackTakeNotes(event.data.params);
  }
});

function trackTakeNotes(params) {
  window.meetric?.track('Take notes click', {
    ...params,
  });
}

export default router;
